<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Meter Reading</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  class="mb-2"
                >
                  <v-autocomplete
                    v-model="form.meter_id"
                    :items="users"
                    :loading="isUsersLoading"
                    :search-input.sync="users_search"
                    :filter="filterObject"
                    clearable
                    hide-details
                    item-text="name"
                    item-value="meter.id"
                    cache-items
                    hide-no-data
                    label="Meter customer"
                    placeholder="Search customer name or account number"
                    :rules="[
                      v => !!v || 'Customer name is required',
                    ]"
                    @change="setPreviousReading"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for customer or meter
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      {{ data.item.name }} - {{ data.item.account_number }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar class="primary font-weight-light white--text">
                        {{ data.item.name.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="data.item.account_number"></v-list-item-subtitle>
                        <br />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <small
                    v-show="form.errors.has('meter_id')"
                    class="validation-error"
                  >{{
                    form.errors.get('meter_id')
                  }}</small>
                </v-col>
                <v-col
                  v-if="action === 'Save'"
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Previous Meter reading"
                    placeholder="130"
                    :value="previous_reading"
                    number
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.current_reading"
                    label="Meter reading"
                    placeholder="130"
                    :rules="meterReadingRules"
                    number
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('current_reading')"
                    class="validation-error"
                  >{{
                    form.errors.get('current_reading')
                  }}</small>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    ref="month_menu"
                    v-model="month_menu"
                    :close-on-content-click="false"
                    :return-value.sync="form.month"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.month"
                        label="Month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.month"
                      no-title
                      type="month"
                      scrollable
                      :rules="[
                        v => !!v || 'Month is required',
                      ]"
                      @change="
                        $refs.month_menu.save(form.month)
                        month_menu = false
                      "
                    >
                    </v-date-picker>
                  </v-menu>
                  <small
                    v-show="form.errors.has('month')"
                    class="validation-error"
                  >{{
                    form.errors.get('month')
                  }}</small>
                </v-col>
                <v-col
                  v-show="action === 'Update'"
                  cols="12"
                  md="6"
                >
                  <v-menu
                    ref="bill_due_at_menu"
                    v-model="bill_due_at_menu"
                    :close-on-content-click="false"
                    :return-value.sync="form.bill_due_at"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.bill_due_at"
                        label="Bill due on"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.bill_due_at"
                      no-title
                      scrollable
                      @change="
                        $refs.bill_due_at_menu.save(form.bill_due_at)
                        bill_due_at_menu = false
                      "
                    >
                    </v-date-picker>
                  </v-menu>
                  <small
                    v-show="form.errors.has('bill_due_at')"
                    class="validation-error"
                  >{{
                    form.errors.get('bill_due_at')
                  }}</small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    meterReading: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      meter_id: '',
      current_reading: '',
      month: '',
      bill_due_at: '',
    }),
    previous_reading: '',
    stations: [],
    types: [],
    users: [],
    isUsersLoading: false,
    isStationsLoading: true,
    isTypesLoading: true,
    action: 'Save',
    users_search: null,
    month_menu: false,
    bill_due_at_menu: false,
  }),
  computed: {
    ...mapGetters(['selectedStation']),
    meters_url() {
      return `meters?station_id=${this.selectedStation}`
    },
    showAutomaticMeterFields() {
      return this.form.mode === 1
    },
  },
  watch: {
    meterReading() {
      if (Object.keys(this.meterReading).length > 0) {
        this.form.update(this.meterReading)
        this.getMeterUser(this.form.meter_id)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
    users_search(value) {
      this.getUsers(value)
    },
    showDialog(value) {
      if (value && this.users.length === 0) {
        this.getStations()
        this.getTypes()
        this.getUsers('')
      }
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || item.account_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveMeterReading()
        } else {
          this.updateMeterReading()
        }
      }
    },
    getStations() {
      this.isStationsLoading = true
      axios
        .get('meter-stations')
        .then(response => {
          this.stations = response.data
          if (this.selectedStation === null) {
            this.setSelectedStation(response.data[0].id)
          }
          this.isStationsLoading = false
        })
        .catch(error => {
          this.isStationsLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    getTypes() {
      this.isTypesLoading = true
      axios
        .get('meter-types')
        .then(response => {
          this.types = response.data
          this.isTypesLoading = false
        })
        .catch(error => {
          this.isTypesLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    getUsers: _.debounce(function (value) {
      this.isUsersLoading = true
      axios
        .get(`users?station_id=${this.selectedStation}&searchByNameAndAccountNo=${value}`)
        .then(response => {
          this.users = response.data.data
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    getMeterUser(meterId) {
      this.isUsersLoading = true
      axios
        .get(`users?searchByMeterID=${meterId}`)
        .then(response => {
          this.users = response.data.data
          this.previous_reading = response.data.data.user.meter.last_reading
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    saveMeterReading() {
      this.form
        .post('meter-readings')
        .then(() => {
          this.$notification.success('Meter reading added successfully')
          this.$emit('close')
          this.$emit('meter-reading-added', this.form.month)
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    updateMeterReading() {
      this.form
        .put(`meter-readings/${this.form.id}`)
        .then(response => {
          this.$notification.success('Meter reading updated successfully')
          if (response.data.has_message_been_resent) {
            setTimeout(() => { this.$notification.success(`${this.form.user_name} will receive SMS with the new meter readings shortly.`) }, 1500)
          }
          this.$emit('close')
          this.$emit('meter-reading-added', this.form.month)
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    setPreviousReading(value) {
      if (value) {
        const selectedUser = this.users.find(user => user.meter.id === value)
        if (selectedUser) {
          this.previous_reading = selectedUser.meter.last_reading
          console.log('🚀 ~ file: AddMeterReadingDialog.vue:396 ~ setPreviousReading ~ selectedUser.meter.last_reading:', selectedUser.meter.last_reading)
        }
      }
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  min-height: 48px !important;
  height: unset !important;
}
</style>
